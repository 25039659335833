<div class="mb-5 mt-5 py-5 pt-10">
  <div class="container-md text-center">
    <div class="row mt-5">
      <div class="col-12">
        <span class="fs-1 " style="font-weight: 600;">Contact Us</span>
        <p class="fs-5 text-center mt-1">
          A member of our team will be in touch soon
        </p>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-9 mx-auto">
        <p  style="font-size:31px; color: #070e0a;">
          Got a problem? Connect with us, and we'll find the solution
        </p>
      </div>
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-11 col-xl-6 text-center mt-3">
        <div class="card border border-secondary">
          <div class="card-body">
            <h5 style="font-size: 2em;" class="d-flex justify-content-center">
              <span class="material-symbols-outlined fs-1 text_color">shopping_bag</span><span class="mt-2 mx-2 py-1">Sales</span> 
            </h5>
            <div class="row">
              <div class="col-md-6 mb-2">
                <div class="card card-inside" style="background: #EDF0F9; margin-top: 20px;">
                  <p class="rounded-pill bg-light d-flex justify-content-between align-items-center p-2 mx-auto mt-2">
                    <span class="material-symbols-outlined me-3 text-primary">
                      call
                      </span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-whatsapp ms-2 whatsapp_app" viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
                      </svg>
                  </p>
                  <!-- <p class="mt-3 text-dark fs-5">+91 8977781354</p> -->
                  <p class="text-dark fs-5">
                    <span>+91 8977781355  <span class="material-symbols-outlined cursor-pointer  mx-2 text-muted" style="cursor:pointer"
                      (click)="copyMobileNumber('8977781355')">
                      content_copy
                    </span></span>
                  </p>
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <div class="card card-inside" style="background: #EDF0F9; margin-top: 20px;">
                  <p class="rounded-pill bg-light d-flex justify-content-center align-items-center p-2 mx-auto mt-2">
                    <span class="material-symbols-outlined text-warning">
                      mail
                      </span>
                  </p>
                  <p class=" text-dark fs-5">sales&commat;cixcent.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
<!-- 
      <div class="col-md-6 text-center mt-3">
        <div class="card border border-secondary" >
          <div class="card-body">
            <h5 style="font-size: 2em;" class="d-flex justify-content-center">
              <span class="material-symbols-outlined fs-1">support_agent</span><span class="mt-2 mx-2 py-1">Support</span> 
            </h5>
            <div class="row">
              <div class="col-md-6 mb-2">
                <div class="card card-inside" style="background: #EDEDED; margin-top: 20px;">
                  <p class="rounded-pill bg-light d-flex justify-content-between align-items-center p-2 mx-auto mt-2">
                    <span class="material-symbols-outlined me-3">
                      call
                      </span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-whatsapp ms-2" viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
                      </svg>
                  </p>
                  <p class="mt-3 text-dark fs-5">+91 9000106052</p>
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <div class="card card-inside" style="background: #EDEDED; margin-top: 20px;">
                  <p class="rounded-pill bg-light d-flex justify-content-center align-items-center p-2 mx-auto mt-2">
                    <span class="material-symbols-outlined">
                      mail
                      </span>
                  </p>
                  <p class="mt-3 text-dark fs-5">support&commat;cixcent.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
