import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact-us-latest',
  templateUrl: './contact-us-latest.component.html',
  styleUrls: ['./contact-us-latest.component.scss']
})
export class ContactUsLatestComponent implements OnInit {

  constructor(
    private readonly _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    {}
  }

  copyMobileNumber(mobileNumber: string){
    navigator.clipboard.writeText(mobileNumber)
    this._snackBar.open('Mobile number Copied Successfully !', 'Close', {
      duration: 2000,
    });
  }

}
