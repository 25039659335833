<footer class="mb-5 mt-5 pt-4 pb-5 bg-black">
  <div class="container-fluid">
    <div class="row mb-4">
      <!-- Support Links -->
      <!-- <div class="col-md-4 mt-5 text-center ">
        <p class="text-white">Support</p>
        <p class="text-white">About Us</p>
        <p class="text-white">Contact Sales</p>
      </div> -->
      
      <!-- Company Info -->
      <div class="col-md-6 mt-5 text-center">
        <p class="text-white fs-5">Cixcent Technologies Private Limited</p>
        <p class=" text-white d-block">Hyderabad, Telangana-500062, India.</p>
        <p class="text-white d-flex justify-content-center"><span class="material-symbols-outlined p-2 fs-6"> copyright
            </span> 2024 Cixcent Technologies Pvt. Ltd.
        </p>
      </div>
      <!-- Contact Info -->
      <div class="col-md-6 mt-5 text-center">
        <p><img src="../../../assets/img/cixcentLogo3.svg" class="img-fluid" alt="Logo"></p>
        <p class="d-flex align-items-center text-white mb-2 justify-content-center ">
          <span class="material-symbols-outlined footer-img text-white me-2">call</span>04045157722
        </p>
        <p class="d-flex align-items-center text-white mb-0 justify-content-center">
          <span class="material-symbols-outlined footer-img  me-2">mail</span>info{{'@'}}cixcent.com
        </p>
      </div>
    </div>
  </div>
</footer>